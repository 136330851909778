.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.navbar {
    background-color: #161616;
    height:75px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav-text {
    color:white;
}
.btn {
    /* background-color:transparent; */
    border:none;
    cursor: pointer;
}
.btn:focus {
    outline:0px;
    box-shadow: none;
}
.btn:disabled {
    cursor: not-allowed;
}
.btn-fill-orange {
    background-color:#ff5000;
    color:white;
    border:none;
}
.App-link {
  color: #61dafb;
}
#imports_table {
    margin:auto; width:-webkit-fill-available; max-width:1180px; border:solid 1px lightgrey; margin-bottom:60px;
}
.table_headers {
    background-color:#f1f4f4!important; color:black;
}
.table-header {
    padding:10px;
    text-align:center;
}
.table-body {
    padding: 10px;
    text-align: center;
}
.table-body-row {
    border-bottom: solid 1px lightgrey;
}
.modal-content {
    margin-top: 160px;
}
.center {
    text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.adiv {
  background: #04CB28;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 18px;
  height: 56px
}

.chat {
  border: none;
  background: #E2FFE8;
  font-size: 15px;
  border-radius: 25px
}

.bg-white {
  border: 1px solid #E7E7E9;
  font-size: 15px;
  border-radius: 25px
}

.myvideo img {
  border-radius: 20px
}

.dot {
  font-weight: bold
}

.form-control {
  border-radius: 25px;
  border: 5px solid #F0F0F0;
  font-size: 12px
  
}

.form-control:focus {
  box-shadow: none
}

.form-control::placeholder {
  font-size: 12px;
  color: #C4C4C4
}

.buttonClass{
background-color: blue !important;
}